import { apiSlice } from "../api/apiSlice";

export const doorStatusLogApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    doorStatusLogDataFetch: builder.mutation({
      query: (data) => ({
        url: "/apiV2/door-status-duration",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useDoorStatusLogDataFetchMutation } = doorStatusLogApi;
