import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { ThemeContext } from "../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import getBase64 from "../../helper/helper";

import { useGetAllFishInfoQuery } from "../../features/fish/fishApi";
import { useCreateFishMutation } from "../../features/fish/fishApi";
import { useUpdateFishMutation } from "../../features/fish/fishApi";
import useTitle from "../../hooks/useTitle";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function Fish() {
  useTitle("Fish Create");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: allFishInfo, isLoading } = useGetAllFishInfoQuery();
  const [createFish] = useCreateFishMutation();

  const [updateFish] = useUpdateFishMutation();

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (allFishInfo.success) {
      defaultData = [...allFishInfo?.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const data = new Date(premesisInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }

  // const handleChange = (e) => {
  //   alert(e.target.name);

  //   setFile(e.target.files[0]);
  //   if (file) {
  //     setImageUrl(URL.createObjectURL(file));
  //   } else {
  //     setImageUrl(null);
  //   }
  // };

  // if (updatePremisesResult || result) {
  //   console.log(updatePremisesResult || result);
  // }

  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      createFish(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      updateFish(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change
    if (e.target) {
      if (e.target.name === "image") {
        getBase64(e.target.files[0])
          .then((base64image) => {
            setUpdateFormData((previousValue) => ({
              ...previousValue,
              [e.target.name]: base64image,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setUpdateFormData((previousValue) => ({
          ...previousValue,
          [e.target.name]: e.target.value,
        }));
      }
    } else {
      //inters here because description textarea sends text directly through "e"
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        description: e,
      }));
    }
    // console.log(updateFormData);
  };

  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Fish Create
      </div>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Add Fish
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Add Fish" : "Update Fish"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div className="form-group">
                      <label>
                        Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Fish Name"
                        // value={name}
                        name="name"
                        defaultValue={updateFormData?.name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                    </div>
                    <div className="form-group">
                      <label>Scientific Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Scientific Name"
                        name="scientific_name"
                        defaultValue={updateFormData?.scientific_name || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Cultivation Time(Month)</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Cultivation Time"
                        name="cultivation_time_month"
                        defaultValue={
                          updateFormData?.cultivation_time_month || ""
                        }
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label>Description</label>
                      <Input
                        name="description"
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        defaultValue={updateFormData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label for="areaMap">Image</label>
                      <input
                        type="file"
                        name="image"
                        placeholder="Image"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        className="form-control"
                      />
                    </div>
                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={150}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Name</HeaderCell>
              <Cell dataKey="name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Scientific Name
              </HeaderCell>
              <Cell dataKey="scientific_name" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Cultivation Time
              </HeaderCell>
              <Cell dataKey="cultivation_time_month" />
            </Column>
            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column>

            <Column
              width={100}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Area Map</HeaderCell>
              <Cell dataKey="area_map">
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setImageShow(true);
                      setImageUrl(rowData?.image);
                      // setImageUrl("https://via.placeholder.com/600/92c952");
                    }}
                  >
                    {/* <FaImage /> */}
                    <img
                      className="premises_map_image"
                      src={rowData?.image || ""}
                    ></img>
                  </div>
                )}
              </Cell>
            </Column>

            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Fish;
