import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { FaEdit, FaImage } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";

import { ThemeContext } from "../../context/ThemeContext";
import { Table, Pagination, Input } from "rsuite";
import getBase64 from "../../helper/helper";

import { useGetAllFishInfoQuery } from "../../features/fish/fishApi";
import { useCreateFishMutation } from "../../features/fish/fishApi";
import { useUpdateFishMutation } from "../../features/fish/fishApi";

import { useGetFishFeedListQuery } from "../../features/fish/fishFeedApi";
import { useCreateFishFeedMutation } from "../../features/fish/fishFeedApi";
import { useUpdateFishFeedMutation } from "../../features/fish/fishFeedApi";
import useTitle from "../../hooks/useTitle";

// import "./styles/premises.css";
const { Column, HeaderCell, Cell } = Table;

function FishFeed() {
  useTitle("Fish Feed");
  const theme = React.useContext(ThemeContext);
  const [show, setShow] = React.useState(false);
  const [createFormSelector, setCreateFormSelector] = useState(true);
  const [reseponseMessage, setResponseMessage] = useState();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(1);
  const [updateFormData, setUpdateFormData] = useState({});
  const [tableLoading, setTableLoading] = useState(true);

  const { data: fishFeedInfo, isLoading } = useGetFishFeedListQuery();

  const [createFishFeed] = useCreateFishFeedMutation();

  const [updateFishFeed] = useUpdateFishFeedMutation();

  const [imageShow, setImageShow] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  let defaultData = [];
  let data = [];

  useEffect(() => {
    if (!isLoading) {
      setTableLoading(false);
    }
  }, [isLoading]);

  if (!isLoading) {
    if (fishFeedInfo.success) {
      defaultData = [...fishFeedInfo?.data];
      defaultData = defaultData?.map((premesisInfo) => {
        const data = new Date(premesisInfo.updated_at);
        const formattedDate = data.toLocaleString();
        return {
          ...premesisInfo,
          updated_at: formattedDate,
        };
      });
    }

    data = defaultData?.filter((v, i) => {
      const start = limit * (page - 1);
      const end = start + limit;
      return i >= start && i < end;
    });
  }
  const handleClose = () => {
    setShow(false);
    setImageShow(false);
  };
  const handleModalOutsideClick = () => {
    setShow(false);
    setResponseMessage("");
  };
  const handleChangeLimit = (dataKey) => {
    setPage(1);
    setLimit(dataKey);
  };
  const formSubmitHandler = (e) => {
    e.preventDefault();

    if (createFormSelector) {
      createFishFeed(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    } else {
      updateFishFeed(updateFormData)
        .unwrap()
        .then((payload) => setResponseMessage(payload?.message))
        .catch((error) => setResponseMessage(error?.status));
    }
  };
  const handleEdit = (id) => {
    // alert(id);
    let formData = defaultData.find((object) => object.id == id) || {};

    delete formData.image; // as updating empty value keeps previous image on db
    setUpdateFormData(formData);
    // console.log(formData);
  };
  const dataChangeHandler = (e) => {
    setResponseMessage(""); //to hide form submit message on change
    if (e.target) {
      if (e.target.name === "image") {
        getBase64(e.target.files[0])
          .then((base64image) => {
            setUpdateFormData((previousValue) => ({
              ...previousValue,
              [e.target.name]: base64image,
            }));
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        setUpdateFormData((previousValue) => ({
          ...previousValue,
          [e.target.name]: e.target.value,
        }));
      }
    } else {
      //inters here because description textarea sends text directly through "e"
      setUpdateFormData((previousValue) => ({
        ...previousValue,
        description: e,
      }));
    }
    // console.log(updateFormData);
  };

  const imageModalOutsideClick = () => {
    setImageShow(false);
  };

  return (
    <div className={`row row-sm ${theme} `}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Fish Feed
      </div>
      <div style={{ textAlign: "right", marginBottom: "1rem" }}>
        <button
          className="btn btn-primary btn-md "
          // style={{ width: "20vw" }}
          onClick={() => {
            setShow(true);
            setCreateFormSelector(true);
            setUpdateFormData({});
          }}
        >
          Add Fish Feed
        </button>
        {/* </div> */}
      </div>
      <Modal
        show={imageShow}
        onHide={handleClose}
        className={`${theme} `}
        onExited={imageModalOutsideClick}
        centered
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>Image</Modal.Title>

            <RxCross2 onClick={imageModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <img
              className="image_inside_modal"
              src={imageUrl}
              alt="modal_image"
            ></img>
          </Modal.Body>
        </div>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        classname={`${theme} `}
        centered
        onExited={handleModalOutsideClick}
      >
        <div className={`${theme}`}>
          <Modal.Header
            className="theme-background theme-text"
            style={{ borderRadius: "0px" }}
          >
            <Modal.Title>
              {createFormSelector ? "Add Fish Feed" : "Update Fish Feed"}
            </Modal.Title>
            <div style={{ color: "green" }}>{reseponseMessage}</div>
            <RxCross2 onClick={handleModalOutsideClick} />
          </Modal.Header>
          <Modal.Body className="theme-background theme-text">
            {" "}
            <div className="col-lg-12 col-xl-12 col-md-12 col-sm-12 zero-padding">
              <div>
                <div>
                  <form onSubmit={formSubmitHandler}>
                    <div class="form-group">
                      <label class="form-label">
                        Feed Grade Name <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        name="feed_grade_name"
                        class="form-control form-select"
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        defaultValue={updateFormData?.feed_grade_name || ""}
                      >
                        <option label="Choose one"></option>
                        <option value="Medicine">Medicine</option>
                        <option value="Generic">Generic</option>
                      </select>
                    </div>
                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Feed Name <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Feed Die Size
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Feed Name"
                        name="feed_name"
                        defaultValue={updateFormData?.feed_name}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                        required
                      />
                      <span className="input-group-addon"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Feed Die Size"
                        name="feed_die_size"
                        defaultValue={updateFormData?.feed_die_size}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Feed Brand Name
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Feed Weight
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Feed Brand Name"
                        name="feed_brand_name"
                        defaultValue={updateFormData?.feed_brand_name}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                      <span className="input-group-addon"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Feed Weight"
                        name="feed_weight"
                        defaultValue={updateFormData?.feed_weight}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Crude Protein
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Crude Fat
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Crude Protein"
                        name="crude_protein"
                        defaultValue={updateFormData?.crude_protein}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                      <span className="input-group-addon"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Crude Fat"
                        name="crude_fat"
                        defaultValue={updateFormData?.crude_fat}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>
                    <div
                      className="input-group theme-background theme-text"
                      style={{ border: "0px" }}
                    >
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", marginLeft: "-0.6rem" }}
                      >
                        Crude Fiber
                      </label>
                      {/* <span class="input-group-addon">-</span> */}
                      <label
                        className="form-control theme-background theme-text"
                        style={{ border: "0px", paddingLeft: "2rem" }}
                      >
                        Crude Moisture
                      </label>
                    </div>
                    <div
                      className="input-group"
                      style={{ paddingBottom: "0.8rem" }}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Crude Fiber"
                        name="crude_fiber"
                        defaultValue={updateFormData?.crude_fiber}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                      <span className="input-group-addon"></span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Crude Moisture"
                        name="crude_moisture"
                        defaultValue={updateFormData?.crude_moisture}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div className="form-group">
                      <label>Description</label>
                      <Input
                        name="description"
                        as="textarea"
                        rows={3}
                        placeholder="Description"
                        defaultValue={updateFormData?.description || ""}
                        onChange={(e) => {
                          dataChangeHandler(e);
                        }}
                      />
                    </div>

                    <div style={{ textAlign: "center" }}>
                      <Button type="submit">Submit</Button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
      <div>
        <div className="table-responsive theme-background theme-text">
          <Table height={500} data={data || []} loading={tableLoading}>
            <Column
              width={50}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Id.</HeaderCell>
              <Cell dataKey="id" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Grade Name</HeaderCell>
              <Cell dataKey="feed_grade_name" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Feed Name</HeaderCell>
              <Cell dataKey="feed_name" />
            </Column>
            <Column
              width={100}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Die Size</HeaderCell>
              <Cell dataKey="feed_die_size" />
            </Column>
            <Column
              width={100}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Brand Name</HeaderCell>
              <Cell dataKey="feed_brand_name" />
            </Column>
            <Column
              width={100}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Weight</HeaderCell>
              <Cell dataKey="feed_weight" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Crude Protein
              </HeaderCell>
              <Cell dataKey="crude_protein" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Crude Fat</HeaderCell>
              <Cell dataKey="crude_fat" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Crude Fiber</HeaderCell>
              <Cell dataKey="crude_fiber" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>
                Crude Moisture
              </HeaderCell>
              <Cell dataKey="crude_moisture" />
            </Column>
            <Column
              width={80}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Description</HeaderCell>
              <Cell dataKey="description" />
            </Column>
            <Column
              width={200}
              align="center"
              flexGrow={1}
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Last Updated</HeaderCell>
              <Cell dataKey="updated_at" />
            </Column>
            <Column
              width={80}
              align="center"
              className={`theme-text theme-table-background`}
            >
              <HeaderCell style={{ color: "#6C88AF" }}>Edit</HeaderCell>
              <Cell>
                {(rowData) => (
                  <div
                    icon="edit"
                    onClick={() => {
                      setShow(true);
                      setCreateFormSelector(false);
                      handleEdit(rowData.id);
                    }}
                  >
                    <FaEdit />
                  </div>
                )}
              </Cell>
            </Column>
          </Table>
          <div style={{ padding: 20 }}>
            <Pagination
              prev
              next
              first
              last
              ellipsis
              boundaryLinks
              maxButtons={5}
              size="lg"
              layout={["total", "-", "limit", "|", "pager", "skip"]}
              total={defaultData.length}
              limitOptions={[10, 30, 50]}
              limit={limit}
              activePage={page}
              onChangePage={setPage}
              onChangeLimit={handleChangeLimit}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default FishFeed;
